/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:ad178577-eb53-4e1b-a999-30182d4074ca",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_vZvgNGjsJ",
    "aws_user_pools_web_client_id": "45gufjr7166c0ar2o2oratvpns",
    "oauth": {},
    "aws_user_files_s3_bucket": "shotwirecomfc53e21aa63e40c7b4f60c37fdf92c0e01858-master",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://6tbj3yvumjgmve6ezsuvxmdknm.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "shotwireAPI",
            "endpoint": "https://0ktbznh2ll.execute-api.us-west-2.amazonaws.com/master",
            "region": "us-west-2"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://fn9zomli45.execute-api.us-west-2.amazonaws.com/master",
            "region": "us-west-2"
        },
        {
            "name": "salesAPI",
            "endpoint": "https://g2nrulhed8.execute-api.us-west-2.amazonaws.com/master",
            "region": "us-west-2"
        }
    ],
    "aws_mobile_analytics_app_id": "cc2c1829daf1431d860060f206637bf3",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
